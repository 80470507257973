import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PublicRoute = ({ children }) => {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn); // Adjust path based on your Redux state structure

  return isLoggedIn ? <Navigate to="/" replace /> : children;
};

export default PublicRoute;
