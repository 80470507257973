import React, { useState } from "react";
import "../styles/sign.css";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
  GoogleAuthProvider,
} from "firebase/auth";
import { auth } from "./firebase";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signInWithPopup } from "firebase/auth";
import { setUserLoggedIn } from "../store/actions/authActions";

const SignUpForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [agreed, setAgreed] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const language = useSelector((state) => state.language.language);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const provider = new GoogleAuthProvider();

  const signInGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      // console.log(result);
      navigate("/");
      dispatch(setUserLoggedIn(result.user));
    } catch (e) {
      console.log(e);
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (!email || !password || !name || !confirmPassword || !phoneNumber) {
      setError(
        `${
          language === "ar"
            ? "يرجى ملء جميع الحقول"
            : "Please fill in all fields"
        }`
      );
      setTimeout(() => setError(""), 3000);
      return;
    }
    if (password !== confirmPassword) {
      setError(
        `${
          language === "ar"
            ? "كلمات المرور غير متطابقة"
            : "Passwords do not match"
        }`
      );
      setTimeout(() => setError(""), 3000);
      return;
    }
    if (!agreed) {
      setError(
        `${
          language === "ar"
            ? "يرجى الموافقة على سياسة الخصوصية"
            : "Please agree to the privacy policy"
        }`
      );
      setTimeout(() => setError(""), 3000);
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      // Update user profile
      await updateProfile(userCredential.user, {
        displayName: name,
      });

      await sendEmailVerification(userCredential.user);
      // console.log("Verification email sent.");

      navigate("/");
    } catch (e) {
      setError(
        `${
          language === "ar"
            ? "هذا البريد الإلكتروني مسجل بالفعل"
            : "This email is already signed up"
        }`
      );
      console.log(e);
      setTimeout(() => setError(""), 3000);
    }
  };
  const handleCheckboxChange = () => {
    setError("");
    setAgreed(!agreed);
  };

  return (
    <main className="flex justify-center items-center h-screen siginBody px-10">
      {error && (
        <p className="absolute top-[10%] bg-red-500 text-white font-bold rounded-xl p-4 border border-gray-500 z-10">
          {error}
        </p>
      )}
      <form
        onSubmit={handleSignUp}
        className="bg-[#CB0404] shadow-md rounded-3xl px-8 lg:px-14 pt-6 pb-4 relative"
      >
        <img
          src="/Media/LogoHomePage.png"
          alt=""
          className="h-16 w-16 rounded-full absolute top-4 left-4"
        />
        <div className="text-center my-3 lg:mb-3">
          <h1 className="text-white text-2xl font-bold mb-2">
            {language === "ar" ? "التسجيل" : "Sign up"}
          </h1>
          <p className="text-white">
            {language === "ar"
              ? "ابحث عن المسار المناسب لمستقبلك"
              : "Find the Right path For Your Future"}
          </p>
        </div>

        <input
          className="signup-input focus:outline-none focus:shadow-outline"
          id="name"
          type="text"
          placeholder={language === "ar" ? "الاسم" : "Name"}
          dir={language === "ar" ? "rtl" : "ltr"}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          className="signup-input focus:outline-none focus:shadow-outline"
          id="email"
          type="email"
          placeholder={
            language === "ar" ? "أدخل بريدك الإلكتروني" : "Enter your Email"
          }
          dir={language === "ar" ? "rtl" : "ltr"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className="signup-input focus:outline-none focus:shadow-outline"
          id="password"
          type="password"
          placeholder={language === "ar" ? "كلمة المرور" : "Password"}
          dir={language === "ar" ? "rtl" : "ltr"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          className="signup-input focus:outline-none focus:shadow-outline"
          id="confirm-password"
          type="password"
          placeholder={
            language === "ar" ? "تأكيد كلمة المرور" : "Confirm password"
          }
          dir={language === "ar" ? "rtl" : "ltr"}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <input
          className="signup-input focus:outline-none focus:shadow-outline"
          id="phone-number"
          type="text"
          placeholder={language === "ar" ? "رقم الهاتف" : "Phone number"}
          dir={language === "ar" ? "rtl" : "ltr"}
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />

        <div className="flex justify-end mb-2 mt-4">
          <input
            type="checkbox"
            id="privacyPolicyCheckbox"
            checked={agreed}
            onChange={handleCheckboxChange}
          />
          <label
            htmlFor="privacyPolicyCheckbox"
            className="ml-2 text-white lg:text-sm text-xs"
          >
            {language === "ar"
              ? "أوافق على الخصوصية والسياسة"
              : "I Agree With Privacy and Policy"}
          </label>
        </div>

        <div className="flex justify-center">
          <button className="sign-button" type="submit">
            {language === "ar" ? "تسجيل" : "Sign up"}
          </button>
        </div>

        <div className="flex justify-center items-center m-2">
          <hr className="my-4 border border-white mx-3 w-28" />
          <p className="text-white text-center text-xs font-bold lg:font-medium lg:text-base">
            {language === "ar"
              ? "أو قم بتسجيل الدخول باستخدام"
              : "Or Sign In With"}
          </p>
          <hr className="my-4 border border-white mx-3 w-28" />
        </div>

        <div className="flex justify-center items-center ">
          <img
            src="/Media/icon-facebook.png"
            alt=""
            className="h-7 w-7 cursor-pointer"
          />
          <img
            onClick={signInGoogle}
            src="/Media/icon-google.webp"
            alt=""
            className="mx-5 h-7 w-7 cursor-pointer"
          />
          <img
            src="/Media/icon-apple.png"
            alt=""
            className="h-7 w-7 cursor-pointer"
          />
        </div>
      </form>
    </main>
  );
};

export default SignUpForm;
