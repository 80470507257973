import { useSelector } from "react-redux";



function AboutUs() {

  const language = useSelector((state) => state.language.language);
  return (
    <section className="px-6 pt-10 lg:pt-0 flex lg:items-center h-screen bg-white">
      {/* vertical line */}
      <aside className={`hidden border-l border-[#CB0404] h-3/4 mx-5 mt-5 ml-16 ${language === 'ar' ? "" : "lg:block"}`}></aside>

      <div className="w-full">
        <h1 className={`text-center text-3xl font-bold text-[#CB0404] mb-1 ${language === "ar" ? "lg:ml-4 lg:text-right" : "lg:text-start"}`}>
          {language === "ar" ? "معـلومـات عنــا" : "About Us"}
        </h1>

        {/* horizontal line */}
        <div className={`flex ${language === 'ar' ? "justify-end" : ""}`}>
          <div className={`hidden lg:block border-b border-[#CB0404] mb-12 ${language === 'ar' ? "mr-8 w-44 mt-1" : "w-32 ml-8"}`}></div>
        </div>

        <div className="lg:hidden border-b border-[#CB0404] mx-auto w-36 mb-12"></div>

        <div className="flex flex-col lg:flex-row">

          {/* logo */}
          <div className={`w-full mt-8 lg:w-[45%] lg:mt-0 ${language === 'ar' ? "block" : "hidden"}`}>
            <div className="flex justify-center">
              <img
                src="/Media/LogoHomePage.png"
                alt=""
                className="w-[40%] rounded-full shadow-xl border border-gray-200"
              />
            </div>

            <p className="text-[#CB0404] font-bold mt-3 text-center text-2xl mb-20">
              {language === "ar" ? "ابدأ بالطريق الصحيح" : "START IT RIGHT"}
            </p>

            <div className="flex justify-start">
              <div className="border-b border-[#CB0404] w-24 mb-4"></div>
            </div>
            <div className="flex justify-start">
              <div className="border-b border-[#CB0404] w-16 mb-4"></div>
            </div>
          </div>

          {/* square */}
          <aside className={`h-4 w-4 mt-1 mr-5 bg-black border border-gray-700 mb-1 lg:mb-0 ${language === "ar" ? "lg:hidden" : ""}`}></aside>

          <p
            className={`w-full lg:w-[55%] text-xl lg:text-2xl font-semibold text-center leading-10 ${language === "ar" ? "lg:text-3xl lg:font-medium" : ""}`}>
            {language === "ar"
              ? `يوفر التطبيق كافة المعلومات عن الجامعات الخاصة بالجيزة والإسكندرية والكليـات والتخصصات 
                            المـتوفرة بهـا وممـيزات كل كلـية. وأيضا
                             إذا كان الطالب لا يعرف الكلية التي يريدها نقدم له 
                             ( Chat Bot ) لمساعدته في اختيار الكلية المناسبة له`

              : `           The application provides all information about
                            the private universities in Giza and Alexandria,
                            the colleges and specializations available in them,
                            and the advantages of each college. Also, if
                            the student does not know which college he wants,
                            we oﬀer him a ( Chat Bot ) to help him choose
                            the appropriate college for him.`}
          </p>

          <aside className={`hidden lg:block h-4 w-4 mt-1 ml-5 bg-black border border-gray-700 mb-1 lg:mb-0 ${language === "ar" ? "" : "lg:hidden"}`}></aside>

          {/* square */}
          <aside className="lg:hidden flex justify-end pb-6">
            <div className="h-4 w-4 mt-1 mr-5 bg-black border border-gray-700"></div>
          </aside>

          <div className={`w-full mt-8 lg:w-[45%] lg:mt-0 ${language === 'ar' ? "hidden" : "block"}`}>
            <div className="flex justify-center">
              <img
                src="/Media/LogoHomePage.png"
                alt=""
                className="w-[40%] rounded-full shadow-xl border border-gray-200"
              />
            </div>

            <p className="text-[#CB0404] font-bold mt-2 text-center text-2xl mb-20">
              {language === "ar" ? "ابدأ بالطريق الصحيح" : "START IT RIGHT"}
            </p>

            <div className="flex justify-end">
              <div className="border-b border-[#CB0404] w-24 mb-4"></div>
            </div>
            <div className="flex justify-end">
              <div className="border-b border-[#CB0404] w-16 mb-4"></div>
            </div>
          </div>
        </div>
      </div>
      <aside className={`hidden border-l border-[#CB0404] h-3/4 mx-5 mt-5 ml-16 ${language === 'ar' ? "lg:block" : ""}`}></aside>
    </section>
  );
}

export default AboutUs;
