import HomePage from "./pages/homepage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SignInForm from "./pages/signin";
import SignUpForm from "./pages/signup";
import UniAlex from "./pages/uniAlex";
import AboutUs from "./pages/aboutUs";
import OverSees from "./pages/overSees";
import KnowPage from "./pages/Know";
import DontKnowPage from "./pages/DontKnow";
import store from "./store/MyStore";
import { Provider, useSelector } from "react-redux";
import NotFoundPage from "./pages/NotFound";
import UniGiza from "./pages/UniGiza";
import MyNavbar from "./components/navbar";
// import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/publicRoute";
import ResetPassword from "./pages/ResetPassword";
import CollInfos from "./pages/CollegeInfos";
import Results from "./pages/Result-Page";
import PleaseVerify from "./pages/PleaseVerf";
import UniDetails from "./pages/UniDetails";
import FilterPage from "./pages/filterPage";
import "./App.css";

function App() {
  const language = useSelector((state) => state.language.language);
  return (
    <div
      className={`relative min-h-screen ${language === "ar" ? "arabic" : ""}`}
    >
      <Provider store={store}>
        <BrowserRouter>
          <MyNavbar />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/over-sees" element={<OverSees />} />
            <Route path="/universities" element={<KnowPage />} />
            <Route path="/email-verification" element={<PleaseVerify />} />
            <Route path="/filterPage/:searchQu" element={<FilterPage />} />
            <Route
              path="/universities/:location/:universityName/:collegeName"
              element={<CollInfos />}
            />
            <Route
              path="/universities/:cityName/:universityName"
              element={<UniDetails />}
            />
            <Route
              path="/ResetPassword"
              element={
                <PublicRoute>
                  <ResetPassword />
                </PublicRoute>
              }
            />
            <Route
              path="/sign-in"
              element={
                <PublicRoute>
                  <SignInForm />
                </PublicRoute>
              }
            />
            <Route
              path="/sign-up"
              element={
                <PublicRoute>
                  <SignUpForm />
                </PublicRoute>
              }
            />
            <Route path="/over-sees/know" element={<KnowPage />} />
            <Route path="/results/" element={<Results />} />
            <Route path="/over-sees/dont-know" element={<DontKnowPage />} />
            <Route path="/universities/alex" element={<UniAlex />} />
            <Route path="/universities/giza" element={<UniGiza />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
