import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  const language = useSelector((state) => state.language.language);

  return (
    <main className="flex justify-center min-h-screen bg-white py-28">
      <div className="flex flex-col">
        {/* Error Container */}
        <section className="flex flex-col items-center">
          <div className="text-red-700 font-bold text-7xl">404</div>
          <h2 className="font-bold text-3xl xl:text-7xl lg:text-6xl md:text-5xl mt-10">
            {language === "ar"
              ? "هذه الصفحة غير موجودة"
              : "This page does not exist"}
          </h2>
          <p className="text-gray-400 font-medium text-sm md:text-xl lg:text-2xl mt-8">
            {language === "ar"
              ? "الصفحة التي تبحث عنها غير موجودة."
              : "The page you are looking for could not be found."}
          </p>
        </section>

        {/* Continue With */}
        <section className="flex flex-col mt-48">
          <div
            className={`text-gray-400 font-bold uppercase text-xl ${
              language === "ar" ? "rtl text-end" : ""
            }`}
          >
            {language === "ar" ? ":يمكنك الذهاب الى" : "Continue With:"}
          </div>
          <div className="flex flex-col items-stretch mt-3">
            <Link
              to="/"
              className="flex flex-row group px-4 py-8 border-t hover:cursor-pointer transition-all duration-200 delay-100"
            >
              <div className="rounded-xl bg-white border border-gray-300 px-3 py-3 md:py-3">
                <i className="fas fa-home mx-auto text-red-600 text-2xl md:text-3xl"></i>
              </div>
              <div className="grow flex flex-col pl-5 pt-2">
                <h3 className="font-bold text-sm md:text-lg lg:text-xl group-hover:underline">
                  {language === "ar" ? "الصفحة الرئيسية" : "Home Page"}
                </h3>
                <p className="font-semibold text-sm md:text-md lg:text-lg text-gray-400 group-hover:text-gray-500 transition-all duration-200 delay-100">
                  {language === "ar"
                    ? "كل شيء يبدأ هنا"
                    : "Everything starts here"}
                </p>
              </div>
              <i className="fas fa-chevron-right text-gray-400 my-auto pr-2 group-hover:text-gray-700 transition-all duration-200 delay-100"></i>
            </Link>
            <Link
              to="/about-us"
              className="flex flex-row group px-4 py-8 border-t hover:cursor-pointer transition-all duration-200 delay-100"
            >
              <div className="rounded-xl bg-white border border-gray-300 px-3 py-3 md:py-3">
                <i className="fa-solid fa-circle-info mx-auto text-red-600 text-2xl md:text-3xl"></i>
              </div>
              <div className="grow flex flex-col pl-5 pt-2">
                <h3 className="font-bold text-sm md:text-lg lg:text-xl group-hover:underline">
                  {language === "ar" ? "معلومات عنا" : "About Us"}
                </h3>
                <p className="font-semibold text-sm md:text-md lg:text-lg text-gray-400 group-hover:text-gray-500 transition-all duration-200 delay-100">
                  {language === "ar"
                    ? "تعرف علينا بشكل أفضل"
                    : "Get To Know Us Better"}
                </p>
              </div>
              <i className="fas fa-chevron-right text-gray-400 my-auto pr-2 group-hover:text-gray-700 transition-all duration-200 delay-100"></i>
            </Link>
            <Link
              to="/universities"
              className="flex flex-row group px-4 py-8 border-t hover:cursor-pointer transition-all duration-200 delay-100"
            >
              <div className="rounded-xl bg-white border border-gray-300 px-3 py-3 md:py-3">
                <i className="fa-solid fa-building-columns mx-auto text-red-600 text-2xl md:text-3xl"></i>
              </div>
              <div className="grow flex flex-col pl-5 pt-2">
                <h3 className="font-bold text-sm md:text-lg lg:text-xl group-hover:underline">
                  {language === "ar" ? "الجامعات" : "Universities"}
                </h3>
                <p className="font-semibold text-sm md:text-md lg:text-lg text-gray-400 group-hover:text-gray-500 transition-all duration-200 delay-100">
                  {language === "ar"
                    ? "استكشف قائمتنا من الجامعات"
                    : "Explore Our List of Universities"}
                </p>
              </div>
              <i className="fas fa-chevron-right text-gray-400 my-auto pr-2 group-hover:text-gray-700 transition-all duration-200 delay-100"></i>
            </Link>
          </div>
        </section>
      </div>
    </main>
  );
};

export default NotFoundPage;
