import React, { useEffect, useState } from "react";
import MyBreadcrumbs from "../components/Breadcrumbs";
import "../styles/homepage.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { db } from "./firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

function UniGiza() {
  const [items, setItems] = useState([]);
  const language = useSelector((state) => state.language.language);

  useEffect(() => {
    const fetchItems = async () => {
      const q = query(
        collection(db, "universities"),
        where("location", "==", "giza")
      );
      const querySnapshot = await getDocs(q);
      const itemList = [];
      querySnapshot.forEach((doc) => {
        itemList.push({ id: doc.id, ...doc.data() });
      });
      setItems(itemList);
    };

    fetchItems();
  }, []);

  // console.log(items);

  return (
    <main>
      <MyBreadcrumbs />
      <h1 className="text-redCode text-2xl font-semibold bg-white text-center pt-5">
        {language === "ar" ? "الجامعات في الجيزة" : "Universities in Giza"}
      </h1>

      <div className="h-screen bg-white">
        <div className="flex flex-col justify-center items-center gap-3 lg:flex-row bg-white">
          {items.map((i) => {
            return (
              <div className="uni-div2">
                <Link className="uni-link" to={`/universities/giza/${i.name}`}>
                  <img className="uni-img2" src={i.image} alt="" />
                </Link>
                <div className="flex justify-center mt-5">
                  <img className="uni-ico" src={i.logo} alt="" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </main>
  );
}

export default UniGiza;
