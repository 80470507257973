import MyBreadcrumbs from "./../components/Breadcrumbs";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function KnowPage() {
  const language = useSelector((state) => state.language.language);

  return (
    <>
      <MyBreadcrumbs />
      <main className="px-4 pt-6 h-screen bg-white">
        <h2 className="text-center text-lg lg:text-3xl font-bold">
          {language === "ar"
            ? "اختر أين تريد الجامعة"
            : "Choose where you want to university"}
        </h2>
        <section className="flex justify-center mt-3">
          <div className="flex flex-col justify-center items-center">
            <img src="/Media/placeholder.png" alt="" className="h-28 lg:h-40" />
            <p className="circular-icon-text">
              {language === "ar" ? "اختر مدينتك" : "Choose your city"}
            </p>
          </div>
        </section>

        <section className="flex justify-evenly mt-5 pb-8">
          <div className="flex flex-col justify-center items-center">
            <Link
              className="flex flex-col items-center"
              to="/universities/alex"
            >
              <img src="/Media/alex.png" alt="" className="circular-icon" />
              <p
                className={`circular-icon-text ${
                  language === "ar" ? "text-2xl" : ""
                }`}
              >
                {language === "ar" ? "الإسكندرية" : "Alexandria"}
              </p>
            </Link>
          </div>

          <div className="flex flex-col justify-center items-center">
            <Link
              className="flex flex-col items-center"
              to="/universities/giza"
            >
              <img src="/Media/giza.png" alt="" className="circular-icon" />
              <p
                className={`circular-icon-text ${
                  language === "ar" ? "text-2xl" : ""
                }`}
              >
                {language === "ar" ? "الجيزة" : "Giza"}
              </p>
            </Link>
          </div>
        </section>
      </main>
    </>
  );
}

export default KnowPage;
