import React, { useEffect, useState } from "react";
import MyBreadcrumbs from "../components/Breadcrumbs";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { db } from "./firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

function Results() {
  const language = useSelector((state) => state.language.language);
  const [searchParams] = useSearchParams();

  const preferredLocation = searchParams.get("preferredLocation");
  const budget = searchParams.get("budget");
  const [items, setItems] = useState([]);
  const [colleges, setColleges] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      // Get hobbies from queryParams
      const hobbies = searchParams.get("interest").split(",");
      const grade = searchParams.get("grade");
      // Get Colleges Which Match your Hobbies
      const collegeQuery = query(
        collection(db, "colleges"),
        where("grade", "<=", parseInt(grade)),
        where("hobbies", "array-contains-any", hobbies)
      );
      const collegesSnapshots = await getDocs(collegeQuery);
      if (collegesSnapshots.empty) {
        setItems([]);
        return;
      }
      setColleges(collegesSnapshots.docs);
      const unisQuery = query(
        collection(db, "universities"),
        where("location", "==", `${preferredLocation}`),
        where("budget", "==", `${budget}`),
        where(
          "colleges",
          "array-contains-any",
          collegesSnapshots.docs.map((doc) => doc.id)
        )
      );
      const querySnapshot = await getDocs(unisQuery);
      const itemList = [];
      querySnapshot.forEach((doc) => {
        itemList.push({ id: doc.id, ...doc.data() });
      });
      setItems(itemList);
    };

    fetchItems();
  }, [budget, preferredLocation, searchParams]);

  return (
    <>
      <header className="bg-white">
        <MyBreadcrumbs />
        <h1 className="bg-white mt-3 text-center lg:font-semibold lg:text-2xl">
          {language === "ar"
            ? `نتائج إجابتك عن الكليات والجامعات المناسبة لك هي:`
            : `The results of your answer the colleges and universities that are suitable for you are:`}
        </h1>
      </header>
      <article className="pt-8 flex flex-col gap-10 bg-white ">
        <div
          className={`flex ml-4 pb-5 items-center gap-3 ${
            language === "ar" ? "justify-end" : ""
          }`}
        >
          <div
            className={`bg-black w-6 h-5 rounded ${
              language === "ar" ? "hidden" : ""
            }`}
          ></div>
          <p className="font-semibold text-lg">
            {language === "ar" ? `:الجامعات` : `The universities:`}
          </p>
          <div
            className={`bg-black w-6 h-5 rounded ${
              language === "ar" ? "mr-5" : "hidden"
            }`}
          ></div>
        </div>

        <div className="flex lg:justify-evenly items-center lg:flex-row flex-col">
          {items.length > 0 ? (
            items.map((uni, index) => (
              <Link
                to={`/universities/${uni.location}/${uni.name}`}
                key={index}
                className="flex items-center lg:justify-evenly lg:flex-row lg:flex flex-col"
              >
                <div className="lg:w-[25%]">
                  <img
                    className="mt-3 lg:mt-0 max-h-24 lg:w-full"
                    src={uni.logo}
                    alt={uni.ar ? uni.ar.name : uni.name}
                  />
                </div>
                <div className="lg:w-[60%]">
                  <img
                    className="mt-3 lg:mt-0 w-96 h-80 border-2 border-gray-500 rounded-3xl"
                    src={uni.image}
                    alt=""
                  />
                </div>
              </Link>
            ))
          ) : (
            <p>
              {language === "ar"
                ? `لا توجد جامعات مطابقة للمعايير.`
                : `No universities match the criteria.`}
            </p>
          )}
        </div>

        <div className="ml-4 pb-6">
          <div
            className={`flex items-center gap-3 ${
              language === "ar" ? "justify-end" : ""
            }`}
          >
            <div
              className={`bg-black w-6 h-5 rounded ${
                language === "ar" ? "hidden" : ""
              }`}
            ></div>
            <p className="font-semibold text-lg">
              {language === "ar" ? `:الكليات` : `The Colleges:`}
            </p>
            <div
              className={`bg-black w-6 h-5 rounded ${
                language === "ar" ? "mr-5" : "hidden"
              }`}
            ></div>
          </div>
          <ul
            className={`flex-col flex ${
              language === "ar" ? "items-end mr-14" : "ml-9"
            }`}
          >
            {colleges.length > 0 ? (
              colleges.map((college, index) => (
                <li key={index}>
                  <p>
                    {language === "ar"
                      ? college.data().commonNameAr
                      : college.data().commonName}
                  </p>
                </li>
              ))
            ) : (
              <p>
                {language === "ar"
                  ? `لا توجد كليات مطابقة للمعايير.`
                  : `No colleges match the criteria.`}
              </p>
            )}
          </ul>
        </div>
      </article>
    </>
  );
}

export default Results;
