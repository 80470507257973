import { useSelector } from "react-redux";
import MyBreadcrumbs from "./../components/Breadcrumbs";
import { Link } from "react-router-dom";

function OverSees() {
  const language = useSelector((state) => state.language.language);
  return (
    <>
      <MyBreadcrumbs />
      <main className="px-4 pt-6 flex flex-col justify-center bg-white min-h-[80%]">
        <p className="text-center text-lg lg:text-3xl font-medium">
          {language === "ar"
            ? "سواء كنت تعرف أو لا تعرف ماذا تدرس أو إذا كنت مغتربًا من الخارج .. سنساعدك على اتخاذ الخيار الصحيح"
            : "Whether you know or do not know what to study or are an expatriate from abroad ..we will help you to make the right choice"}
        </p>

        <section className="flex justify-evenly mt-16">
          <article>
            <Link
              className="flex flex-col items-center"
              to="/over-sees/dont-know"
            >
              <img
                src="/Media/DONT_KNOW_ICON.png"
                alt=""
                className="circular-icon"
              />
              <p
                className={`circular-icon-text ${
                  language === "ar" ? "text-2xl" : ""
                }`}
              >
                {language === "ar" ? "لا أعرف" : "Don't Know"}
              </p>
            </Link>
          </article>

          <article>
            <Link className="flex flex-col items-center" to="/over-sees/know">
              <img src="/Media/KNOW.png" alt="" className="circular-icon" />
              <p
                className={`circular-icon-text ${
                  language === "ar" ? "text-2xl" : ""
                }`}
              >
                {language === "ar" ? "أعرف" : "Know"}
              </p>
            </Link>
          </article>
        </section>
      </main>
    </>
  );
}

export default OverSees;
