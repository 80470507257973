import React, { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useSelector } from "react-redux";

function ResetPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const language = useSelector(state => state.language.language)

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleResetPassword = async (event) => {
    event.preventDefault();
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setMessage("Password reset email sent!");
      })
      .catch((error) => {
        setMessage("Error: " + error.message);
      });
  };

  return (
    <>
      <article>
        <div className="h-screen flex flex-col justify-center items-center bg-white">
          <form
            className="flex flex-col gap-3 items-center"
            onSubmit={handleResetPassword}
          >
            <label>
              {language === 'ar' ? `أدخل بريدك الإلكتروني` : `Enter Your Email`}
            </label>
            <input
              className="border border-3 border-redCode rounded p-1 w-[305px]"
              type="text"
              placeholder="Please Enter Your Email"
              value={email}
              onChange={handleEmailChange}
            />
            <button className="bg-redCode text-white text-lg p-2 rounded">
              {language === 'ar' ? `إرسال` : `Submit`}
            </button>
          </form>
          {message && <p>{message}</p>}
          <p>
            {language === 'ar' ? `نصيحة: تأكد من إدخال البريد الإلكتروني الذي قمت بالتسجيل به.` : `Tip: Make sure to enter the email that you registered with.`}
          </p>
        </div>
      </article>
    </>
  );
}

export default ResetPassword;
