import React, { useEffect, useState } from "react";
import MyBreadcrumbs from "../components/Breadcrumbs";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { db } from "./firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

function UniDetails() {
  const language = useSelector((state) => state.language.language);
  const [isExpanded, setIsExpanded] = useState(false);
  const { universityName } = useParams();
  const [items, setItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // const [colleges, setColleges] = useState(null);

  // useEffect(() => {
  //   const fetchItems = async () => {
  //     const ref = doc(db, "universities", universityName);
  //     const uni = await getDoc(ref);
  //     setItem(uni.data());
  //   };

  //   fetchItems();
  // }, []);

  useEffect(() => {
    const fetchItem = async () => {
      const querySnapshot = await getDocs(
        query(
          collection(db, "universities"),
          where("name", "==", universityName)
        )
      );
      if (!querySnapshot.empty) {
        const uniSnapshot = querySnapshot.docs[0];
        const collegesSnapshots = await getDocs(
          collection(uniSnapshot.ref, "specific_college_data")
        );
        const data = {
          ...uniSnapshot.data(),
          id: uniSnapshot.id,
          colleges: collegesSnapshots.docs.map((doc) => doc.data()),
        };
        setItem(data);
        setIsLoading(true);
      } else {
        console.log("No document found with the name:", universityName);
      }
    };

    fetchItem();
  }, [universityName]);

  // useEffect(() => {
  //   const fetchItems = async () => {
  //     const q = query(collection(db, "colleges"));
  //     const querySnapshot = await getDocs(q);
  //     const itemList = [];
  //     querySnapshot.forEach((doc) => {
  //       itemList.push({ id: doc.id, ...doc.data() });
  //     });
  //     setColleges(itemList);
  //   };

  //   fetchItems();
  // }, []);

  // console.log(coll);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <MyBreadcrumbs />
      {isLoading && (
        <section className="bg-white min-h-screen pt-5">
          {/* University Name */}
          <h1 className="text-center font-bold text-textColor text-3xl">
            {language === "ar" ? items.nameAr : items.name.replace(/_/g, " ")}
          </h1>
          <div className="p-3 mt-4 gap-4 lg:flex lg:flex-row lg:justify-evenly flex flex-col items-center">
            <div
              className={`flex gap-3 flex-col items-center ${
                language === "ar" ? "" : "hidden"
              }`}
            >
              <img className="h-24" src={items.logo} alt="logo" />
              <img
                className="rounded-[80px] shadow-gray-500 shadow-lg w-[502px] h-[395px]"
                src={items.image}
                alt="college"
              />
            </div>

            <div>
              <section>
                <div
                  className={`flex gap-2 items-center ${
                    language === "ar" ? "justify-end" : ""
                  }`}
                >
                  <div
                    className={`bg-black w-5 h-5 rounded-lg ${
                      language === "ar" ? "hidden" : ""
                    }`}
                  ></div>
                  <h2 className="font-medium text-2xl">
                    {language === "ar" ? `الموقع` : `Location`}
                  </h2>
                  <div
                    className={`bg-black w-5 h-5 rounded-lg ${
                      language === "ar" ? "" : "hidden"
                    }`}
                  ></div>
                </div>

                {/* University Location */}
                <p
                  className={`${
                    language === "ar"
                      ? "pr-7 text-right"
                      : "pl-7 lg:max-w-[80%]"
                  }`}
                >
                  {language === "ar" ? items.addressAr : items.address}
                </p>
              </section>

              <section>
                <div
                  className={`flex gap-2 items-center ${
                    language === "ar" ? "justify-end" : ""
                  }`}
                >
                  <div
                    className={`bg-black w-5 h-5 rounded-lg ${
                      language === "ar" ? "hidden" : ""
                    }`}
                  ></div>
                  <h2 className="font-medium text-2xl">
                    {language === "ar" ? `معلومة` : `Information`}
                  </h2>
                  <div
                    className={`bg-black w-5 h-5 rounded-lg ${
                      language === "ar" ? "" : "hidden"
                    }`}
                  ></div>
                </div>

                {/* University Location */}

                <div className=" lg:w-[700px] xl:w-[950px]">
                  <p
                    className={`${
                      language === "ar" ? "pr-7 text-right" : "w-full pl-7"
                    }`}
                    style={{ transition: "height 0.3s ease" }}
                  >
                    {isExpanded
                      ? language === "ar"
                        ? items.infoAr
                        : items.info
                      : language === "ar"
                      ? `${items.infoAr.substring(0, 80)}`
                      : `${items.info.substring(0, 80)}...`}
                  </p>
                  <button
                    className="pl-7 text-right text-redCode"
                    onClick={toggleExpanded}
                  >
                    {isExpanded
                      ? `${language === "ar" ? "اظهار اقل" : "Show Less"}`
                      : `${language === "ar" ? "عرض المزيد" : "Show More"}`}
                  </button>
                </div>
              </section>

              {/*    <section className="my-4">
                <div
                  className={`flex gap-2 items-center ${
                    language === "ar" ? "justify-end" : ""
                  }`}
                >
                  <div
                    className={`bg-black w-5 h-5 rounded-lg ${
                      language === "ar" ? "hidden" : ""
                    }`}
                  ></div>
                  <h2 className="font-medium text-2xl">
                    {language === "ar" ? `معلومات` : `Information`}
                  </h2>
                  <div
                    className={`bg-black w-5 h-5 rounded-lg ${
                      language === "ar" ? "" : "hidden"
                    }`}
                  ></div>
                </div>
                <div>
                  <div className="md:w-[700px] lg:w-[790px]">
                    // University Info 
                    <p
                      className={`${
                        language === "ar"
                          ? "pr-7 text-right"
                          : "max-w-[90%] pl-7"
                      }`}
                      style={{ transition: "height 0.3s ease" }}
                    >
                      {isExpanded
                        ? language === "ar"
                          ? items.infoAr
                          : items.info
                        : language === "ar"
                        ? `${items.infoAr.substring(0, 80)}`
                        : `${items.info.substring(0, 80)}...`}
                    </p>
                    <button
                      className="pl-7 text-right text-redCode"
                      onClick={toggleExpanded}
                    >
                      {isExpanded
                        ? `${language === "ar" ? "اظهار اقل" : "Show Less"}`
                        : `${language === "ar" ? "عرض المزيد" : "Show More"}`}
                    </button>
                  </div>
                </div> 
              </section> */}
              <section className="my-4">
                <div
                  className={`flex gap-2 items-center ${
                    language === "ar" ? "justify-end" : ""
                  }`}
                >
                  <div
                    className={`bg-black w-5 h-5 rounded-lg ${
                      language === "ar" ? "hidden" : ""
                    }`}
                  ></div>
                  <h2 className="font-medium text-2xl">
                    {language === "ar" ? `الكليات` : `Colleges`}
                  </h2>
                  <div
                    className={`bg-black w-5 h-5 rounded-lg ${
                      language === "ar" ? "" : "hidden"
                    }`}
                  ></div>
                </div>
                {/* faculties list */}
                <ul
                  className={`${
                    language === "ar" ? "pr-7 text-right" : "pl-7"
                  }`}
                >
                  {items.colleges.map((college, index) => {
                    return (
                      <li className="">
                        <Link
                          to={`/universities/${items.location}/${items.name}/${
                            language === "ar" ? college.nameAr : college.name
                          }`}
                          key={index}
                          className="text-lg hover:underline hover:text-textColor  w-auto"
                        >
                          {language === "ar" ? college.nameAr : college.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </section>

              <section
                className={`flex gap-2 items-center ${
                  language === "ar" ? "justify-end" : ""
                }`}
              >
                <div
                  className={`bg-black w-5 h-5 rounded-lg ${
                    language === "ar" ? "hidden" : ""
                  }`}
                ></div>
                <h2 className="font-medium text-[20px]">
                  {language === "ar" ? `الخط الساخن : ` : `HOTLINE : `}
                  {/* hotline number */}
                  <span className="text-textColor font-bold">
                    {language === "ar" && items.hotline === "Not Available"
                      ? items.hotline
                      : items.hotline}
                  </span>
                </h2>
                <div
                  className={`bg-black w-5 h-5 rounded-lg ${
                    language === "ar" ? "" : "hidden"
                  }`}
                ></div>
              </section>
            </div>

            <div
              className={`flex gap-3 flex-col items-center ${
                language === "ar" ? "hidden" : ""
              }`}
            >
              <img className="h-24" src={items.logo} alt="logo" />
              <img
                className="rounded-[80px] shadow-gray-500 shadow-lg w-[502px] h-[395px]"
                src={items.image}
                alt="college"
              />
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default UniDetails;
