import { combineReducers } from "redux";
import languageReducer from "./ChangeLanguage";
import userReducer from "./userReducer";

const rootReducer = combineReducers({
  language: languageReducer,
  user: userReducer,
});

export default rootReducer;
