import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "./firebase";
import { useSelector } from "react-redux";

function FilterPage() {
  const para = useParams();
  const language = useSelector((state) => state.language.language);

  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      const q = query(
        collection(db, "universities"),
        where("colleges", "array-contains", para.searchQu)
      );
      const querySnapshot = await getDocs(q);
      const itemList = [];
      querySnapshot.forEach((doc) => {
        itemList.push({ id: doc.id, ...doc.data() });
      });
      setItems(itemList);
    };

    fetchItems();
  }, [para.searchQu]);

  return (
    <>
      <main className="min-h-[90%] flex justify-center flex-col">
        <h1 className="text-redCode text-2xl font-semibold bg-white text-center lg:pt-5 mb-5">
          {language === "ar"
            ? "الجامعات بناءً على كليتك التي اخترتها"
            : "Universities Based on your College you Chose"}
        </h1>

        <div className="lg:grid lg:grid-cols-3 lg:gap-3 bg-white flex flex-col justify-center items-center gap-3">
          {items.map((item) => {
            return (
              <div className="flex flex-col items-center">
                <Link
                  className="uni-link"
                  to={`/universities/${item.location}/${item.name}`}
                >
                  <img className="uni-img2" src={item.image} alt="" />
                </Link>
                <div className="flex justify-center mt-5">
                  <img className="uni-ico" src={item.logo} alt="" />
                </div>
              </div>
            );
          })}
        </div>
      </main>
    </>
  );
}

export default FilterPage;
