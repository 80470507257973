import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function HomePage() {
  const [isTextExpanded, setIsTextExpanded] = useState(false);
  const language = useSelector((state) => state.language.language);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  const handleButtonClick = () => {
    setIsTextExpanded(!isTextExpanded);
  };

  return (
    <>
      <main className="px-4 pt-8 bg-white h-[calc(100vh-3rem)] relative">
        {isLoggedIn || (
          <div className="lg:hidden flex justify-between relative z-10">
            <button className="bg-white p-2 rounded-xl border-2 border-gray-500">
              <Link to="/sign-in">
                {" "}
                {language === "ar" ? `تسجيل الدخول  ` : `Sign In`}
              </Link>
            </button>
            <button className="bg-white p-2 rounded-xl border-2 border-gray-500">
              <Link to="/sign-up">
                {" "}
                {language === "ar" ? `تسجيل   ` : `Sign Up`}
              </Link>
            </button>
          </div>
        )}
        <div
          className={`hidden lg:block absolute bottom-0 h-[84vh]    ${
            language === "ar" ? "left-0" : "right-0"
          }`}
        >
          <img
            className="h-full object-cover object-left"
            src={
              language === "ar" ? "/Media/flipped.png" : "/Media/logo2edit.PNG"
            }
            alt=""
          />
        </div>
        <div
          cla
          className={`z-0 absolute top-0 w-full lg:w-[72%] xl:w-[60%] lg:z-0 ${
            language === "ar" ? "left-0" : "right-0"
          }`}
        >
          <img
            src={
              language === "ar"
                ? "/Media/mobile - rtl.png"
                : "/Media/mobile.png"
            }
            alt="traingle"
          />
        </div>

        <div
          className={`flex gap-4 flex-col items-center lg:flex-row ${
            language === "ar" ? "lg:justify-end" : ""
          }`}
        >
          <div className={`${language === "ar" ? "lg:hidden" : "lg:ml-28"}`}>
            <img
              className="imgLogo border-2 shadow-xl shadow-gray-300 rounded-full"
              src="Media/LogoHomePage.png"
              alt="Logo"
            />
            <div>
              <div className="lg:border-t border-red-800 -translate-x-6 w-52 mt-9"></div>
            </div>
          </div>

          <div className="flex flex-col gap-4 items-center text-center">
            <h1 className="text-black text-3xl lg:text-6xl mx-10">
              {language === "ar"
                ? `كليـــة مــن اختيـــارك`
                : `College Of Your Choice`}
            </h1>
            <h2 className="text-black text-xl lg:text-2xl">
              {language === "ar" ? `ابدأ بالطريق الصحيح` : `START IT RIGHT`}
            </h2>
          </div>

          <div
            className={`hidden ${
              language === "ar" ? "lg:block lg:mr-20" : "lg:hidden"
            }`}
          >
            <img
              className="imgLogo border-2 shadow-xl shadow-gray-300 rounded-full"
              src="Media/LogoHomePage.png"
              alt="Logo"
            />
            <div>
              <div className="lg:border-t border-red-800 -translate-x-6 w-52 mt-9"></div>
            </div>
          </div>
        </div>

        <section
          className={`text-white flex flex-col my-3 lg:flex-row items-center ${
            language === "ar" ? "lg:justify-end" : "gap-4"
          }`}
        >
          {/* Social Logos */}
          <div className="justify-center flex items-center lg:items-start lg:flex-col min-w-24 gap-4">
            <div className="cursor-pointer hover:scale-125 transition duration-200 ease-in-out">
              <Link to="https://www.facebook.com/profile.php?id=61558616190808&mibextid=LQQJ4d">
                <i
                  className="fa-brands text-4xl fa-square-facebook"
                  style={{ color: "#1877f2" }}
                ></i>
              </Link>
            </div>
            <div className="cursor-pointer hover:scale-125 transition duration-200 ease-in-out bg-gradient-to-b flex items-center rounded justify-center w-9 h-9 lg:w-8 lg:h-8 from-purple-500 via-red-500 to-yellow-500">
              <Link to="https://www.instagram.com/cyc_24_?igsh=OWhhaHgzZDVnOGV1&utm_source=qr">
                <i className="fa-brands fa-instagram text-white text-3xl"></i>
              </Link>
            </div>
            <div className="cursor-pointer hover:scale-125 transition duration-200 ease-in-out">
              <Link to="https://www.tiktok.com/@cyc_2024_?_t=8lck8o4684T&_r=1">
                <i className="text-xxl fa-brands fa-tiktok text-4xl text-black "></i>
              </Link>
            </div>
          </div>

          <div
            className={`hero-paragraph relative text-center max-w-screen-lg ${
              language === "ar"
                ? "lg:text-right lg:w-[60%] lg:hero-paragraph"
                : "lg:text-left"
            }`}
          >
            <h2
              className={`my-5 text-2xl text-black mb-4 font-semibold lg:text-3xl xl:text-4xl ${
                language === "ar"
                  ? "lg:text-right mr-5"
                  : "lg:max-w-[60%] xl:max-w-[75%]"
              }`}
            >
              {language === "ar"
                ? `اختيار الجامعة يعني اختيار مستقبلك`
                : `Choosing a university means choosing you future`}
            </h2>
            <div
              className={`flex justify-center gap-1 ${
                language === "ar" ? "lg:justify-end" : "lg:justify-start"
              }`}
            >
              <div
                className={`bg-redCode h-6 w-8 rounded-lg hidden mt-1 mr-2 ${
                  language === "ar" ? "lg:hidden" : "lg:block"
                }`}
              ></div>
              <div
                className={`text-lg lg:w-full ${
                  language === "ar"
                    ? "lg:text-right lg:text-2xl xl:max-w-[99%]"
                    : "lg:text-xl lg:max-w-[60%] xl:max-w-[75%]"
                }`}
              >
                <p
                  className={`text-black ${
                    isTextExpanded ? "" : "lg:truncate"
                  }`}
                >
                  {language === "ar"
                    ? `إذا قررت اختيار الكلية المناسبة لك، يجب أن تتأكد من أنك قد اخترت المستقبل الصحيح بالنسبة لك، الذي تريد تحقيقه، 
    والذي تأمل أن تكون فيه بعد عدة سنوات. ليكون اختيارك صحيحًا، يجب أن تمتلك معلومات كاملة حول الجامعة، التخصص، 
    وفرص العمل.`
                    : `So when you decide to choose the college that is right for you, you must be sure that you have chosen the 
    right future for you, the one you want to achieve, and the one you hope to be in after several years. For your 
    choice to be correct, you must have complete information about the university, specialization, and job 
    opportunities.`}
                </p>
                <div className="hidden lg:flex justify-center lg:justify-end mt-3">
                  <button
                    className="bg-white border border-black shadow-gray-300 font-bold drop-shadow-2xl shadow-2xl text-redCode lg:text-xl text-sm py-2 px-3 rounded-2xl hover:bg-redCode hover:text-white transition duration-200 ease-in-out"
                    onClick={handleButtonClick}
                  >
                    {language === "ar"
                      ? isTextExpanded
                        ? "إظهار أقل"
                        : "عرض المزيد"
                      : isTextExpanded
                      ? "Show less"
                      : "Show more"}
                  </button>
                </div>
              </div>
              <div
                className={`bg-redCode h-6 w-8 rounded-lg hidden mt-1 ml-2 ${
                  language === "ar" ? "lg:block" : "lg:hidden"
                }`}
              ></div>
            </div>

            <div className="flex items-end flex-col gap-3 mt-14 mb-5">
              <div className="lg:hidden border-t w-52 border-redCode"></div>
              <div className="lg:hidden border-t w-24 border-redCode"></div>
              <div className="lg:hidden bg-redCode w-8 h-6 rounded"></div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default HomePage;
