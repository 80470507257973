import React, { useEffect, useState } from "react";
import "../styles/sign.css";
import { auth } from "./firebase";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setUserLoggedIn } from "../store/actions/authActions";

const FloatingAlert = ({ message }) => (
  <div className="absolute top-[10%] bg-red-500 text-white font-bold rounded-xl p-4 border border-gray-600">
    <p>{message}</p>
  </div>
);

const SignInForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const language = useSelector((state) => state.language.language);
  const provider = new GoogleAuthProvider();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (location.state && location.state.showAlert) {
      setShowAlert(true);
      const timeout = setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [location.state]);

  const signInGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      // console.log(result);
      navigate("/");
      dispatch(setUserLoggedIn(result.user));
    } catch (e) {
      console.log(e);
    }
  };

  const signIn = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      dispatch(setUserLoggedIn(userCredential.user));
      navigate("/");
    } catch (error) {
      setError(
        `${
          language === "ar"
            ? "بريد إلكتروني أو كلمة مرور غير صالحة"
            : "Invalid Email or Password"
        }`
      );
      console.log(error);
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  return (
    <main className="flex justify-center items-center h-screen px-10 siginBody ">
      {showAlert && (
        <FloatingAlert
          message={
            language === "ar"
              ? "يرجى تسجيل الدخول أولاً للوصول إلى هذه الصفحة"
              : "Please sign in first to access this page"
          }
        />
      )}

      {error && (
        <div className="absolute top-[10%] bg-red-500 text-white font-bold rounded-xl p-4 border border-gray-600 ">
          {error}
        </div>
      )}

      <form
        onSubmit={signIn}
        className="bg-[#CB0404] shadow-md rounded-3xl px-8 lg:px-14 pt-6 pb-6 relative"
      >
        <img
          src="/Media/LogoHomePage.png"
          alt=""
          className="h-16 w-16 rounded-full absolute top-4 left-4"
        />
        <div className="text-center mb-10">
          <h1 className="text-white text-xl font-bold lg:mb-6 mb-8">
            {language === "ar" ? "تسجيل الدخول" : "Sign in"}
          </h1>
          <p className="text-white">
            {language === "ar"
              ? "ابحث عن المسار المناسب لمستقبلك"
              : "Find the Right path For Your Future"}
          </p>
        </div>

        <input
          className="flex justify-center shadow appearance-none border rounded-2xl w-full py-1.5 px-5 text-gray-700 leading-tight mb-5 focus:outline-none focus:shadow-outline drop-shadow-xl"
          id="email"
          type="email"
          placeholder={
            language === "ar" ? "أدخل بريدك الإلكتروني" : "Enter your Email"
          }
          dir={language === "ar" ? "rtl" : "ltr"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          className="flex justify-center shadow appearance-none border rounded-2xl w-full py-1.5 px-5 text-gray-700 leading-tight mb-5 focus:outline-none focus:shadow-outline drop-shadow-xl"
          id="password"
          type="password"
          placeholder={language === "ar" ? "كلمة المرور" : "Password"}
          dir={language === "ar" ? "rtl" : "ltr"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <div className="flex justify-center">
          <button className="sign-button" type="submit">
            {language === "ar" ? "تسجيل الدخول" : "Log in"}
          </button>
        </div>

        <Link
          className="flex justify-center text-white text-sm mb-6 mt-2"
          to="/ResetPassword"
        >
          {language === "ar" ? "نسيت كلمة المرور" : "Forget password"}
        </Link>

        <div className="flex justify-center items-center my-1">
          <hr className="my-4 border border-white mr-3 w-32" />
          <p className="text-white text-center text-xs font-bold lg:font-medium lg:text-base">
            {language === "ar"
              ? "أو قم بتسجيل الدخول باستخدام"
              : "Or Sign In With"}
          </p>
          <hr className="my-4 border border-white ml-3 w-32" />
        </div>

        <div className="flex justify-center items-center ">
          <img
            src="/Media/icon-facebook.png"
            alt=""
            className="h-7 w-7 cursor-pointer"
          />
          <img
            onClick={signInGoogle}
            src="/Media/icon-google.webp"
            alt=""
            className="mx-5 h-7 w-7 cursor-pointer"
          />
          <img
            src="/Media/icon-apple.png"
            alt=""
            className="h-7 w-7 cursor-pointer"
          />
        </div>
      </form>
    </main>
  );
};

export default SignInForm;
