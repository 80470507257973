import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function MyBreadcrumbs() {
  const location = useLocation();
  const language = useSelector((state) => state.language.language);
  const navigate = useNavigate();

  let currentLink = "";

  const crumbTextMap = {
    "over-sees": {
      en: "OVER SEES STUDENTS - LOCATION",
      ar: "طلاب الخارج - الموقع",
    },
    universities: {
      en: "Universities",
      ar: "الجامعات",
    },
    know: {
      en: "Know",
      ar: "اعرف",
    },
    "dont-know": {
      en: "Don't Know",
      ar: "لا أعرف",
    },
    alex: {
      en: "Universities in Alexandria",
      ar: "الجامعات في الإسكندرية",
    },
    giza: {
      en: "Universities in Giza",
      ar: "الجامعات في الجيزة",
    },
    details: {
      en: "Universities",
      ar: "الجامعات",
    },
    "6th_of_October_University": {
      en: "6th of October University",
      ar: "جامعة ٦ أكتوبر",
    },
    MUST_University: {
      en: "MUST University",
      ar: "جامعة مصر للعلوم والتكنولوجيا",
    },
    MSA_University: {
      en: "MSA University",
      ar: "جامعة أكتوبر للعلوم الحديثة والآداب",
    },
    Ahram_Canadian_University: {
      en: "Ahram Canadian University",
      ar: "جامعة الأهرام الكندية",
    },
    Zewail_University: {
      en: "Zewail University",
      ar: "مدينة زويل",
    },
    Arab_Academy_for_Science_Technology_and_Maritime_Transport: {
      en: "Arab Academy for Science Technology and Maritime Transport",
      ar: "الأكاديمية العربية للعلوم والتكنولوجيا والنقل البحري",
    },
    Nile_University: {
      en: "Nile University",
      ar: "جامعة النيل",
    },
    Pharos_University: {
      en: "Pharos University",
      ar: "جامعة فاروس",
    },
    Arab_Academy_for_Science_Technology_and_Maritime_Transport_Alexandria: {
      en: "Arab Academy for Science Technology and Maritime Transport Alexandria",
      ar: "الأكاديمية العربية للعلوم والتكنولوجيا والنقل البحري",
    },
    Singur_University: {
      en: "Singur University",
      ar: "جامعة سينغور",
    },
    "Egypt_Japan_University_of_Science_and_Technology_(E-JUST)": {
      en: "Egypt Japan University of Science and Technology (E-JUST)",
      ar: "الجامعة المصرية اليابانية للعلوم والتكنولوجيا",
    },
    "mass-communications": {
      en: "Mass Communications",
      ar: "كلية الإعلام",
    },
    results: {
      en: "Results",
      ar: "النتيجة",
    },
  };

  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb) => {
      currentLink += `/${crumb}`;
      const linkText = decodeURI(
        crumbTextMap[crumb]
          ? crumbTextMap[crumb][language] || crumbTextMap[crumb]["en"]
          : crumb
      );

      return (
        <div
          className={`pt-5 flex ${
            language === "ar" ? "pr-5" : "pl-5"
          } max-w-[100vw] overflow-clip`}
          key={crumb}
        >
          {language === "ar" ? (
            <>
              <img
                src="/Media/images - rtl.png"
                alt=""
                className="h-4 lg:h-5 mt-1"
              />
              <Link
                className="text-sm lg:text-lg font-semibold ml-6 line-clamp-1"
                to={currentLink}
              >
                {linkText}
              </Link>
            </>
          ) : (
            <>
              <Link
                className="text-sm lg:text-lg font-semibold mr-6 line-clamp-1"
                to={currentLink}
              >
                {linkText}
              </Link>
              <img
                src="/Media/images.png"
                alt=""
                className="hidden md:block lg:block h-4 lg:h-5 mt-1"
              />
            </>
          )}
        </div>
      );
    });

  if (language === "ar") {
    crumbs.reverse();
  }
  return (
    <div className={`flex bg-white ${language === "ar" ? "justify-end" : ""}`}>
      {language === "ar" ? (
        <>
          <Link onClick={() => navigate(-1)}>
            <img
              className="h-8 lg:h-10 mt-3 absolute left-[1%]"
              src="/Media/Back_icon-removebg-preview - rtl.png"
              alt="back button"
            />
          </Link>
          {crumbs}
        </>
      ) : (
        <>
          {crumbs}
          <Link onClick={() => navigate(-1)}>
            <img
              className="h-8 lg:h-10 mt-3 absolute right-[1%]"
              src="/Media/Back_icon-removebg-preview.png"
              alt="back button"
            />
          </Link>
        </>
      )}
    </div>
  );
}

export default MyBreadcrumbs;
