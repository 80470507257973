import MyBreadcrumbs from "./../components/Breadcrumbs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { db } from "./firebase";
import { collection, getDocs } from "firebase/firestore";
import Select from "react-select";

function DontKnowPage() {
  const [colleges, setColleges] = useState([]);
  const language = useSelector((state) => state.language.language);
  const navigate = useNavigate();
  const [hobbies, setHobbies] = useState([]);
  const options = colleges.map((college) => ({
    value: college.id,
    label: language === "ar" ? college.commonNameAr : college.commonName,
  }));
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchItems = async () => {
      const querySnapshot = await getDocs(collection(db, "colleges"));
      const itemList = [];
      querySnapshot.forEach((doc) => {
        itemList.push({ id: doc.id, ...doc.data() });
      });
      setColleges(itemList);
    };

    fetchItems();
  }, []);

  // console.log(colleges);

  useEffect(() => {
    const fetchItems = async () => {
      const querySnapshot = await getDocs(collection(db, "hobbies"));
      const itemList = [];
      querySnapshot.forEach((doc) => {
        itemList.push({ id: doc.id, ...doc.data() });
      });
      setHobbies(itemList);
    };

    fetchItems();
  }, []);

  // console.log(hobbies);

  const handleDownload = (e) => {
    e.preventDefault();
  };

  const [formData, setFormData] = useState({
    highSchoolAverage: "",
    favoriteCollege: "",
    preferredLocation: "",
    budget: "",
    interests: [],
  });

  const handleChange = (e, name, value) => {
    if (e && e.target) {
      const { target } = e;
      const { name, value, options, multiple } = target;

      if (multiple) {
        const selectedValues = Array.from(options)
          .filter((option) => option.selected)
          .map((option) => option.value);
        setFormData({
          ...formData,
          [name]: selectedValues,
        });
      } else {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    for (const key in formData) {
      if (formData.hasOwnProperty(key) && formData[key] === "") {
        setError(
          language === "ar"
            ? "يرجى ملء جميع الحقول"
            : "Please fill in all fields"
        );
        setTimeout(() => setError(""), 3000);
        return;
      }
    }

    if (
      isNaN(parseFloat(formData.highSchoolAverage)) ||
      parseFloat(formData.highSchoolAverage) < 1 ||
      parseFloat(formData.highSchoolAverage) > 100
    ) {
      setError(
        language === "ar"
          ? "الرجاء إدخال نسبة صحيحة بين 1 و 100"
          : "Please enter a valid percentage between 1 and 100"
      );
      setTimeout(() => setError(""), 3000);
      return;
    }

    const { highSchoolAverage,interests, favoriteCollege, preferredLocation, budget } = formData;

    const queryString = `?grade=${highSchoolAverage}&interest=${interests}&favCollege=${favoriteCollege}&preferredLocation=${preferredLocation}&budget=${budget}`;

    navigate(`/results${queryString}`);
    // console.log(formData)
  };

  return (
    <>
      <MyBreadcrumbs />
      <main className="flex flex-col bg-white min-h-screen items-center pb-10">
        {error && (
          <p className="absolute top-[10%] bg-red-500 text-white font-bold rounded-xl p-4 border border-gray-500 z-10">
            {error}
          </p>
        )}
        <p className="font-semibold my-4 text-xl text-center">
          {language === "ar"
            ? `أجب على الأسئلة لتتعرف على الجامعة... مناسبة لك.`
            : `Answer questions to learn about the university... Suitable for you.`}
        </p>
        <div className="bg-[#CB0404] shadow-md rounded-3xl p-8 w-[50%] min-w-96">
          <form onSubmit={handleSubmit}>
            <div className="mb-4 dk-input-div">
              <label className="dk-label">
                {language === "ar"
                  ? `١- ما هو معدلك في المدرسة الثانوية؟`
                  : `1- What is your average in high school?`}
              </label>
              <input
                type="number"
                name="highSchoolAverage"
                value={formData.highSchoolAverage}
                onChange={(e) =>
                  handleChange(e, "highSchoolAverage", e.target.value, setError)
                }
                className="dont-know-input"
              />
            </div>
            <div className="mb-4 dk-input-div">
              <label className="dk-label">
                {language === "ar"
                  ? `٢- هل ترغب في الكلية في الجيزة أم الإسكندرية؟`
                  : `2- Do you want college in Giza or Alexandria?`}
              </label>
              <div className="flex justify-center">
                <button
                  type="button"
                  className={`choices-button border-gray-300 focus:border-indigo-500 mr-2 ${
                    formData.preferredLocation === "giza"
                      ? "bg-indigo-700 text-white"
                      : "bg-white text-red-700 hover:bg-gray-100"
                  }`}
                  onClick={() =>
                    handleChange(null, "preferredLocation", "giza")
                  }
                >
                  {language === "ar" ? `الجيزة` : `Giza`}
                </button>
                <button
                  type="button"
                  className={`choices-button border-gray-300 focus:border-indigo-500 ${
                    formData.preferredLocation === "alex"
                      ? "bg-indigo-700 text-white"
                      : "bg-white text-red-700 hover:bg-gray-100"
                  }`}
                  onClick={() =>
                    handleChange(null, "preferredLocation", "alex")
                  }
                >
                  {language === "ar" ? `الإسكندرية` : `Alexandria`}
                </button>
              </div>
            </div>
            <div className="mb-4 dk-input-div">
              <label htmlFor="college" className="dk-label">
                {language === "ar"
                  ? `٣- ما هي كليتك المفضلة؟`
                  : `3- What is your favorite college?`}
              </label>

              <Select
                className={`p-2 rounded-2xl w-[80%] ${
                  language === "ar" ? "text-right" : ""
                }`}
                name="favoriteCollege"
                value={options.find(
                  (option) => option.value === formData.favoriteCollege
                )}
                onChange={(selectedOption) =>
                  handleChange(null, "favoriteCollege", selectedOption.value)
                }
                options={options}
                placeholder={
                  language === "ar"
                    ? "اختر كليتك المفضلة"
                    : "Select Your Favorite College"
                }
              />
            </div>
            <div className="mb-4 dk-input-div">
              <label className="dk-label">
                {language === "ar"
                  ? `٤- ما هي ميزانيتك المحدودة؟`
                  : `4- What is your limited budget?`}
              </label>
              <div className="flex justify-center">
                <button
                  type="button"
                  className={`choices-button border-gray-300 focus:border-indigo-500 mr-2 ${
                    formData.budget === "low"
                      ? "bg-indigo-700 text-white"
                      : "bg-white text-red-700 hover:bg-gray-100"
                  }`}
                  onClick={() => handleChange(null, "budget", "low")}
                >
                  {language === "ar" ? `منخفض` : `Low`}
                </button>
                <button
                  type="button"
                  className={`choices-button border-gray-300 focus:border-indigo-500 mr-2 ${
                    formData.budget === "medium"
                      ? "bg-indigo-700 text-white"
                      : "bg-white text-red-700 hover:bg-gray-100"
                  }`}
                  onClick={() => handleChange(null, "budget", "medium")}
                >
                  {language === "ar" ? `متوسط` : `Medium`}
                </button>
                <button
                  type="button"
                  className={`choices-button border-gray-300 focus:border-indigo-500 ${
                    formData.budget === "high"
                      ? "bg-indigo-700 text-white"
                      : "bg-white text-red-700 hover:bg-gray-100"
                  }`}
                  onClick={() => handleChange(null, "budget", "high")}
                >
                  {language === "ar" ? `عالي` : `High`}
                </button>
              </div>
            </div>
            <div className="mb-4 dk-input-div">
              <label className="dk-label">
                {language === "ar"
                  ? `٥- ما هي اهتماماتك وهواياتك؟`
                  : `5- What are your interests and hobbies?`}
              </label>
              <Select
                className={`p-2 rounded-2xl w-[80%] ${
                  language === "ar" ? "text-right" : ""
                }`}
                name="interests"
                value={formData.interests.map((option) => ({
                  value: option,
                  label: option,
                }))}
                onChange={(selectedOptions) =>
                  handleChange(
                    selectedOptions,
                    "interests",
                    selectedOptions.map((option) => option.value)
                  )
                }
                id="college"
                isMulti
                options={hobbies.map((hobby) => ({
                  value: language === "ar" ? hobby.nameAr : hobby.name,
                  label: language === "ar" ? hobby.nameAr : hobby.name,
                }))}
                placeholder={
                  language === "ar"
                    ? "اختر هواياتك المفضلة"
                    : "Select Your Favorite Hobbies"
                }
              />
            </div>
            <div className="flex justify-center">
              <button type="submit" className="sign-button">
                {language === "ar" ? `تم` : `Done`}
              </button>
            </div>
          </form>
          {/* Download button */}
          <div className="flex align-middle justify-center mt-3">
            <button
              type="button"
              className="w-[50%] min-w-64 flex justify-center items-center bg-black rounded-2xl text-white font-bold p-2 hover:text-black hover:bg-white transition-all duration-200 ease-in-out"
              onClick={handleDownload}
            >
              <div>
                {language === "ar"
                  ? `قم بتنزيل التطبيق للتحدث مع روبوت الدردشة`
                  : `Get the applications to talk with chat Bot`}
              </div>
              <div className="bg-white w-16 h-8 rounded-2xl text-black hover:text-white hover:bg-black transition-all duration-200 ease-in-out flex justify-center items-center">
                <i className="fa-solid fa-download"></i>
              </div>
            </button>
          </div>
        </div>
      </main>
    </>
  );
}

export default DontKnowPage;
