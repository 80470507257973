import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function PleaseVerify() {
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const language = useSelector(state => state.language.language)

  useEffect(() => {
    if (user && user.emailVerified) {
      navigate("/");
    }
    if (!user) {
      navigate("/");
    }
  }, [user, navigate]);

  return (
    <>
      <article>
        <div className="h-screen flex-col flex justify-center items-center bg-white">
          <h1 className="text-center text-red-700 font-bold text-3xl lg:text-6xl">
            {language === 'ar' ? 'يجب التحقق من البريد الإلكتروني أولا' : 'Email Verification Required'}
          </h1>
          <p className="text-center w-[70%] text-gray-600 font-medium text-sm md:text-xl lg:text-2xl mt-8">
            {language === 'ar' ? 'تتطلب هذه الصفحة التحقق من البريد الإلكتروني للوصول. يرجى التحقق من صندوق الوارد الخاص بك وتحقق من بريدك الإلكتروني لإكمال عملية التسجيل' : 'This page requires email verification to access Please check your inbox and verify your email to complete the registration process'}
          </p>
        </div>
      </article>
    </>
  );
}

export default PleaseVerify;
