import React, { useEffect, useState } from "react";
import MyBreadcrumbs from "./../components/Breadcrumbs";
import { useSelector } from "react-redux";
import { db } from "./firebase"; // assuming your Firebase initialization and exports are in a file named firebase.js
import {
  collection,
  getDocs,
  query,
  where,
  or
} from "firebase/firestore";
import { useParams } from "react-router-dom";

function CollInfos() {
  const language = useSelector((state) => state.language.language);

  const { universityName, collegeName } = useParams();

  const [collegeData, setCollegeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    try {
      async function fetchCollegeData() {
        setLoading(true);
        try {
          // Replace "name" with the actual field name used in your Firestore if different
          const universitiesRef = collection(db, "universities");
          const universityQuery = query(
            universitiesRef,
            where("name", "==", universityName)
          );
          const universityQuerySnapshot = await getDocs(universityQuery);

          if (universityQuerySnapshot.empty) {
            throw new Error("No matching university found.");
          }

          const universityDocRef = universityQuerySnapshot.docs[0].ref;
          const collegeQuery = query(
            collection(universityDocRef, "specific_college_data"),
            or(
              where("name", "==", collegeName),
              where("nameAr", "==", collegeName)
            )
          );
          const collegeQuerySnapshot = await getDocs(collegeQuery);

          if (collegeQuerySnapshot.empty) {
            throw new Error("No matching college found.");
          }

          setCollegeData(collegeQuerySnapshot.docs[0].data());
        } catch (err) {
          console.error(err);
          setError(err.message);
        } finally {
          setLoading(false);
        }
      }

      fetchCollegeData();
    } catch (e) {
      console.log(e);
    }
  }, [universityName, collegeName]);

  // console.log(collegeData.majors);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <MyBreadcrumbs />
      <section className="bg-white h-screen">
        <h1 className="text-textColor text-2xl font-semibold text-center p-3">
          {language === "ar" ? `${collegeData.nameAr}` : `${collegeData.name}`}
        </h1>
        <div className="flex items-center flex-col lg:flex-row gap-8 justify-between p-5 px-10">
          <div className="flex flex-wrap justify-start gap-3">
            {collegeData.majors.map((c) => {
              return (
                <div className="infos flex-col px-2  py-4 gap-3 flex lg:flex-row w-full max-w-72 items-center border-t-2 border-gray-200 shadow-lg shadow-gray-400 rounded-full">
                  <p className="text-center line-clamp-2  font-semibold lg:font-bold  lg:w-[440px]  ">
                    {language === "ar" ? `${c.nameAr}` : `${c.name}`}
                  </p>
                </div>
              );
            })}
          </div>
          <div className="left flex flex-col group  gap-8">
            {/* {collegeData.prices.map((college, index) => {
              const isEven = index % 2 === 0;
              return (
                <div
                  className={`flex items-center ${
                    isEven ? "bg-white" : "bg-red-300"
                  } justify-center shadow-lg rounded-xl w-[160px] shadow-gray-400`}
                >
                  <div className="bg-redCode py-2  flex  flex-col gap-2 rounded-xl items-center w-[140px]">
                    <p className="text-white font-semibold text-lg">
                      {language === "ar" ? `إنجليزي` : `English`}
                    </p>
                    <img
                      className="self-start w-16"
                      src="/Media/Price.png"
                      alt=""
                    />
                    <p className="text-white font-bold">
                      {language === "ar" ? `٤٥ ألف جنيه مصري` : `45K EGP`}
                    </p>
                  </div>
                </div>
              );
            })} */}

            {collegeData.prices ? (
              collegeData.prices.map((college, index) => {
                const isEven = index % 2 === 0;
                return (
                  <div
                    className={`flex items-center ${
                      isEven ? "bg-white" : "bg-red-300"
                    } justify-center shadow-lg rounded-xl w-[160px] shadow-gray-400`}
                  >
                    <div className="bg-redCode py-2  flex  flex-col gap-2 rounded-xl items-center w-[140px]">
                      <p className="text-white font-semibold text-lg">
                        {language === "ar"
                          ? `${college.category} `
                          : `${college.category} `}
                      </p>
                      <img
                        className="self-start w-16"
                        src="/Media/Price.png"
                        alt=""
                      />
                      <p className="text-white font-bold">
                        {language === "ar"
                          ? `جنيه مصري ${college.price} `
                          : `${college.price} EGP`}
                      </p>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="flex items-center justify-center shadow-lg shadow-gray-400 rounded-xl w-[160px] bg-white">
                <div className="py-2 flex flex-col gap-2 items-center w-[140px] bg-redCode">
                  <p className="text-white font-semibold text-lg">
                    {language === "ar" ? `سعر` : `Price`}
                  </p>
                  <img className="w-16" src="/Media/Price.png" alt="" />
                  <p className="text-white font-bold">
                    {language === "ar"
                      ? `جنيه مصري ${collegeData.price}`
                      : `${collegeData.price} EGP`}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default CollInfos;
