import { useEffect, useRef, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage } from "./../store/actions/ChangeLanguage";
import "../styles/navbar.css";
import { signOut } from "@firebase/auth";
import { auth } from "../pages/firebase";
import { setUserLoggedOut } from "../store/actions/authActions";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../pages/firebase";

function MyNavbar() {
  const [isSearchVisible, setSearchVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const selectedLanguage = useSelector((state) => state.language.language);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);

  const [selectedItemId, setSelectedItemId] = useState("");

  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      const q = query(collection(db, "colleges"));
      const querySnapshot = await getDocs(q);
      const itemList = [];
      querySnapshot.forEach((doc) => {
        itemList.push({ id: doc.id, ...doc.data() });
      });
      setItems(itemList);
    };

    fetchItems();
  }, []);

  console.log(selectedItemId);

  const queryHandler = () => {
    navigate(`/filterPage/${selectedItemId}`);
  };

  useEffect(() => {
    const handlePageHide = () => {
      if (isLoggedIn) {
        handleLogout();
      }
    };

    window.addEventListener("pagehide", handlePageHide);

    return () => {
      window.removeEventListener("pagehide", handlePageHide);
    };
  }, [isLoggedIn]);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const toggleSearch = () => {
    setSearchVisible(!isSearchVisible);
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        localStorage.removeItem("persist:root");
        dispatch(setUserLoggedOut());
        navigate("/");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleLanguageChange = (e) => {
    const language = e.target.value;
    dispatch(changeLanguage(language));

    // const htmlElement = document.querySelector('html');

    // if (language === 'ar') {
    //   htmlElement.setAttribute('dir', 'rtl');
    // } else {
    //   htmlElement.setAttribute('dir', 'ltr');
    // }
  };

  return (
    <>
      <header>
        <nav className="bg-[#CB0404] drop-shadow-xl shadow-xl">
          <div className="px-2 flex justify-between h-12 items-center">
            <div className="flex-shrink-0 flex">
              <Link to="/">
                <img
                  className="h-10 rounded-full"
                  src="/Media/LogoHomePage.png"
                  alt="Logo"
                />
              </Link>
            </div>
            <NavLink to="/" className="hidden lg:block custom-navlink">
              {selectedLanguage === "en" ? "HOME" : "الرئيسية"}
            </NavLink>
            <NavLink to="/about-us" className="hidden lg:block custom-navlink">
              {selectedLanguage === "en" ? "ABOUT US" : "معلومات عنا"}
            </NavLink>
            <NavLink
              to="/universities"
              end
              className="hidden lg:block custom-navlink"
            >
              {selectedLanguage === "en" ? "UNIVERSITIES" : "الجامعات"}
            </NavLink>
            <NavLink to="/over-sees" className="hidden lg:block custom-navlink">
              {selectedLanguage === "en"
                ? "OVER SEES STUDENTS - LOCATION"
                : "طلاب الخارج - الموقع"}
            </NavLink>
            <NavLink
              to="/universities/"
              className="hidden lg:block custom-navlink"
            >
              {selectedLanguage === "en" ? "COLLEGE" : "الكلية"}
            </NavLink>
            {isLoggedIn ? (
              <>
                {user.displayName !== null &&
                  user.displayName !== undefined && (
                    <p className="hidden lg:block text-white xl:text-base lg:text-xs px-2 py-2 font-bold">
                      {selectedLanguage === "en"
                        ? `Welcome, ${user.displayName}!`
                        : `!مرحبًا, ${user.displayName}`}
                    </p>
                  )}
                <button
                  onClick={handleLogout}
                  className="hidden lg:block custom-navlink"
                >
                  {selectedLanguage === "en" ? "LOG OUT" : "تسجيل الخروج"}
                </button>
              </>
            ) : (
              <>
                <NavLink
                  to="/sign-in"
                  className="hidden lg:block custom-navlink"
                >
                  {selectedLanguage === "en" ? "SIGN IN" : "تسجيل الدخول"}
                </NavLink>
                <NavLink
                  to="/sign-up"
                  className="hidden lg:block custom-navlink"
                >
                  {selectedLanguage === "en" ? "SIGN UP" : "تسجيل"}
                </NavLink>
              </>
            )}
            <div className="hidden lg:block">
              <div className="ml-4 flex items-center md:ml-6">
                <div className={`relative `}>
                  <div className="select-wrapper">
                    <div className="language-icon">
                      <img src="/Media/language.svg" alt="" />
                    </div>
                    <select
                      className="block appearance-none w-full bg-white border font-semibold border-gray-800 text-gray-900 py-1.5 px-4 pr-8 pl-10 rounded-xl cursor-pointer focus:outline-none focus:bg-white focus:border-gray-500 transition-all duration-300 shadow-md"
                      onChange={handleLanguageChange}
                      value={selectedLanguage}
                    >
                      <option className="font-semibold" value="en">
                        English
                      </option>
                      <option className="font-semibold" value="ar">
                        العربية
                      </option>
                    </select>
                    <div className="arrow-icon">
                      <svg
                        className="h-5 w-6 fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 12L6 8h8z" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex items-center">
                    <select
                      className="rounded-lg mx-2 px-2 py-1.5 border-gray-600 border w-[65%] max-w-44"
                      aria-label="Select Item"
                      onChange={(e) => setSelectedItemId(e.target.value)}
                    >
                      {items.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.id}
                        </option>
                      ))}
                    </select>

                    <i
                      className="fa-solid fa-magnifying-glass mx-3"
                      onClick={queryHandler}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex lg:hidden">
              <button
                onClick={toggleNavbar}
                type="button"
                className="
                        inline-flex items-center justify-center p-2 rounded-md
                         text-white hover:text-gray-700 hover:bg-white
                         focus:outline-none focus:bg-white focus:text-gray-700 transition 
                         duration-200 ease-in-out"
                aria-label="Main menu"
                aria-expanded="false"
              >
                <svg
                  className={`${isOpen ? "hidden" : "block"} h-6 w-6`}
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  ></path>
                </svg>
                <svg
                  className={`${isOpen ? "block" : "hidden"} h-6 w-6`}
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
          </div>

          {/* Mobile menu, show/hide based on menu state */}
          <div
            className={`${
              isOpen ? "block" : "hidden"
            } lg:hidden transition-all duration-300`}
          >
            <div className="px-2 pt-2 pb-3 sm:px-3">
              <NavLink to="/" className="block custom-navlink text-center">
                {selectedLanguage === "en" ? "HOME" : "الرئيسية"}
              </NavLink>
              <NavLink
                to="/about-us"
                className="block custom-navlink text-center my-1"
              >
                {selectedLanguage === "en" ? "ABOUT US" : "معلومات عنا"}
              </NavLink>
              <NavLink
                to="/universities"
                end
                className="block custom-navlink text-center my-1"
              >
                {selectedLanguage === "en" ? "UNIVERSITIES" : "الجامعات"}
              </NavLink>
              <NavLink
                to="/over-sees"
                className="block custom-navlink text-center my-1"
              >
                {selectedLanguage === "en"
                  ? "OVER SEES STUDENTS - LOCATION"
                  : "طلاب الخارج - الموقع"}
              </NavLink>
              <NavLink
                to="/universities/"
                className="block custom-navlink text-center my-1"
              >
                {selectedLanguage === "en" ? "COLLEGE" : "الكلية"}
              </NavLink>
              {isLoggedIn ? (
                <>
                  {user.displayName !== null &&
                    user.displayName !== undefined && (
                      <p className="text-white xl:text-base lg:text-xs text-center px-2 py-2 font-bold">
                        {selectedLanguage === "en"
                          ? `Welcome, ${user.displayName}!`
                          : `!مرحبًا, ${user.displayName}`}
                      </p>
                    )}
                  <button
                    onClick={handleLogout}
                    className="block w-full custom-navlink text-center"
                  >
                    {selectedLanguage === "en" ? "LOG OUT" : "تسجيل الخروج"}
                  </button>
                </>
              ) : (
                <>
                  <NavLink
                    to="/sign-in"
                    className="text-center block custom-navlink"
                  >
                    {selectedLanguage === "en" ? "SIGN IN" : "تسجيل الدخول"}
                  </NavLink>
                  <NavLink
                    to="/sign-up"
                    className="text-center block custom-navlink"
                  >
                    {selectedLanguage === "en" ? "SIGN UP" : "تسجيل"}
                  </NavLink>
                </>
              )}

              <div className="mt-2 ml-4 flex items-center gap-2 justify-center">
                <div className="relative w-36">
                  <div className="select-wrapper">
                    <div className="language-icon">
                      <img src="/Media/language.svg" alt=""></img>
                    </div>
                    <select
                      onChange={handleLanguageChange}
                      className="block appearance-none w-full bg-white border font-semibold border-gray-800 py-1.5 px-4 pr-8 pl-10 rounded-xl cursor-pointer focus:outline-none focus:bg-gray-600 focus:border-gray-500 hover:bg-gray-100 transition-all duration-300"
                    >
                      <option className="font-semibold" value="en">
                        English
                      </option>
                      <option className="font-semibold" value="ar">
                        العربية
                      </option>
                    </select>
                    <div className="arrow-icon">
                      <svg
                        className="h-5 w-6 fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 12L6 8h8z" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <select
                    className="rounded-lg mx-2 px-2 py-1.5 border-gray-600 border w-[65%] max-w-44"
                    aria-label="Select Item"
                    onChange={(e) => setSelectedItemId(e.target.value)}
                  >
                    {items.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.id}
                      </option>
                    ))}
                  </select>

                  <i
                    className="fa-solid fa-magnifying-glass mx-3"
                    onClick={queryHandler}
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

export default MyNavbar;
